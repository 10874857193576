import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

const Hero = ({ showPerson }) => {
  return (
    <header className="hero">
      {showPerson && (
        <StaticImage
          src="../assets/cross.png"
          placeholder="blurred"
          className="hero-hands-praying"
          alt="cross"
        />
      )}
    </header>
  )
}

export default Hero
