import React from "react"
import {
  FaFacebook,
  FaYoutube
} from "react-icons/fa"
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://www.facebook.com/groups/lookingfromwithin" alt="icon">
          <FaFacebook className="social-icon facebook-icon"></FaFacebook>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/@MyStuffNZ" alt="icon">
          <FaYoutube className="social-icon youtube-icon"></FaYoutube>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
